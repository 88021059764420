import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center', // Center the modalView vertically
    alignItems: 'center',
  },
  modalView: {
    width: '100%', // Use 100% of the screen width
    // Remove the fixed height so the view can shrink or grow
    minHeight: 150,    // Minimum height for the modal when there are few questions
    maxHeight: '80%',  // Maximum height for the modal when there are many questions
    backgroundColor: 'rgba(24, 24, 24, 1)',
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    opacity: 0.98,
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  answerButton: {
    backgroundColor: 'black',
    padding: 10,
    marginVertical: 10,
    width: '100%',
    flexDirection: 'row', // to align the text and the icon
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderWidth: 2,
    borderColor: 'transparent', // to keep the button size the same even when not selected
  },
  closeButton: {
    position: 'absolute', // Position the close button absolutely
    top: 10, // Distance from the top of the modalView
    right: 10, // Distance from the right of the modalView
    backgroundColor: 'transparent',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    color: 'white',
  },
  answerText: {
    color: 'white',
  },
});

export default styles;
