import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
  quizContainer: {
    flex: 1,
    height: '100%',
    backgroundColor: 'black',
  },
  videoContainer: {
    width: '100%',
  },
  fullScreenVideo: {
    position: 'absolute',
    width: '100%',
  },
  replayButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -45 }, { translateY: -45 }],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  playButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -45 }, { translateY: -45 }],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  endMessageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    margin: 20,
    borderRadius: 20,
    // Add a subtle shadow for modern depth
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 5,
  },
  endMessageText: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '600',
    letterSpacing: 1,
  },
  blurOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    justifyContent: 'center',
    zIndex: 502,
    alignItems: 'center',
  },
  reportedWarning: {
    color: 'red',
    fontSize: 18,
    textAlign: 'center',
    padding: 20,
  },
  watchAnywayButton: {
    backgroundColor: 'blue',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  watchAnywayText: {
    color: '#FFFFFF',
    fontSize: 16,
  },
});

export default styles;
