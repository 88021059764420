// AnimatedHoverableButton.tsx
import React, { useRef } from 'react';
import { Pressable, Animated, StyleProp, ViewStyle } from 'react-native';

type AnimatedHoverableButtonProps = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
};

const AnimatedHoverableButton: React.FC<AnimatedHoverableButtonProps> = ({
  onPress,
  style,
  children,
}) => {
  const hoverScale = useRef(new Animated.Value(1)).current;

  const handleHoverIn = () => {
    Animated.timing(hoverScale, {
      toValue: 1.1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  const handleHoverOut = () => {
    Animated.timing(hoverScale, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  return (
    <Animated.View style={[{ transform: [{ scale: hoverScale }] }, style]}>
      <Pressable onPress={onPress} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
        {children}
      </Pressable>
    </Animated.View>
  );
};

export default AnimatedHoverableButton;
