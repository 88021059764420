// LauncherScreen.tsx
import React, { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet, Dimensions } from 'react-native';
import { CommonActions, StackActions, useNavigation } from '@react-navigation/native';
import { useAuth } from '../../context/AuthContext';
import { useDeepLink } from '../../context/DeepLinkContext';
import * as Linking from 'expo-linking';

// Reuse your same GIF asset from SubmitClip
const wavingGif = require('../../assets/gifs/clipdle-waving.gif');

export default function LauncherScreen() {
  const navigation = useNavigation();
  const { isAuthenticated, userProfile } = useAuth();
  const { deepLinkUrl, setDeepLinkUrl } = useDeepLink();

  const [isDeciding, setIsDeciding] = useState(true);

  useEffect(() => {
    // We'll parse the deep link if it's present

    Linking.getInitialURL().then((initialUrl) => {
      if (initialUrl) {
        const urlParts = initialUrl.split('/');

        // e.g. clipdle.tv/video/:videoIds/:initialVideoId/:username
        // "videoIds" is 3rd-last, "initialVideoId" is 2nd-last, "username" is last
        if (urlParts.length >= 6) 
        {
          const videoIds = urlParts[urlParts.length - 3];
          const initialVideoId = urlParts[urlParts.length - 2];
          const username = urlParts[urlParts.length - 1];

          if ( isAuthenticated )
          {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [
                  {
                    name: 'MainTab',
                    state: {
                      routes: [
                        {
                          name: 'QuizViewerProfile',
                          params: {
                            videoIds: [videoIds],
                            initialVideoId,
                            username,
                          },
                        },
                      ],
                    },
                  },
                ],
              })
            );
          }
          else
          {
            // Navigate directly to PublicVideoScreen
            navigation.dispatch(
              StackActions.replace('PublicVideoScreen', {
                  videoIds: [videoIds],
                  initialVideoId,
                  username,
              })
            );
          }

          // Optionally clear the deep link so it doesn’t trigger again
          setDeepLinkUrl('');
          setIsDeciding(false);
          return;
            return;
        }

        // Otherwise, if no deep link, check auth
        else if (isAuthenticated && userProfile) {
          // If they're logged in, go to the main tab
          navigation.dispatch(StackActions.replace('MainTab'));
        } else {
          // If not logged in, go to the login flow
          navigation.dispatch(StackActions.replace('LoginNavigatorScreen'));
        }
      }

      setIsDeciding(false);
    });

    setIsDeciding(false);
  }, [deepLinkUrl, isAuthenticated]);

  // While we haven't navigated yet, show a quick loading screen with the waving GIF
  if (isDeciding) {
    return (
      <View style={styles.container}>
        <View style={styles.wavingGifContainer}>
          <Image source={wavingGif} style={styles.wavingGif} />
        </View>
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    );
  }

  // If we've already navigated away, this screen won't show, but return null as a fallback
  return null;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wavingGifContainer: {
    width: Dimensions.get('window').width * 0.5,
    height: Dimensions.get('window').height * 0.3,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  wavingGif: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  loadingText: {
    color: 'white',
    fontSize: 22,
    textAlign: 'center',
  },
});
