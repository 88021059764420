import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Text } from 'react-native';
import CustomAlert from '../Hooks/Alert/Alert';
import { useNavigation } from '@react-navigation/native';
import firebase from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Filter from 'bad-words';
import styles from './style';

import { useCustomAlert } from '../../hooks/useCustomAlert';

import { API_URL } from '@env';
import { StackNavigationProp } from '@react-navigation/stack';
import { LoginStackParamList } from '../../navigators/LoginNavigator';

const SignUpScreen = () => {
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
  const { setIsAuthenticated, setUserProfile } = useAuth();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');

  const { showCustomAlert, hideCustomAlert, alertProps } = useCustomAlert();

  const filter = new Filter();

  const handleNextStep = async () => {

    if (step < 5) {

      // Ensure username is unique and valid
      if(step === 1){
        if(username.length < 3 || username.length > 20){
          showCustomAlert("Invalid Username", "Username must be 3-20 characters long.");
        } else {
          const userExists = await checkUserExists();
          
          if (userExists) {
            showCustomAlert("Error", "Username is taken, please enter another username");
          } else {
            setStep(step + 1);
          }
        } 
      } else if (step === 2){
        if(isValidEmail()){
          const emailExists = await checkEmailExists();
          if(emailExists){
            showCustomAlert("Email Taken", "It looks like that email is already in use, please use another or log in");
          } else {
            setStep(step + 1);
          }
        } else {
          showCustomAlert("Invalid email", "Please enter a valid email");
        }
      } else if (step === 3){

        const validPassword = isValidPassword();

        // No further logic because validPassword will alert the user if the password is invalid
        if(validPassword){
          setStep(step + 1);
        }
        
      } else if (step === 4) {
        if (!isValidName()) {
          showCustomAlert("Invalid Name", "Name must be between 1 and 30 characters long.");
        } else {
          setStep(step + 1);
        }
      }  

    } else {
      if(!isValidBio()){ 
        showCustomAlert("Invalid Bio", "Bio must be less than 140 characters long.");
      } else {
        handleSignUp();
      }
    }
  };

  const isValidName = () => {
    return name.length > 0 && name.length <= 30;
  };
  
  const isValidBio = () => {
    return bio.length <= 140;
  };

  const isValidPassword = () => {
    let errorMessage = '';

    if (password.length < 8 || password.length > 20) {
      errorMessage += 'Password must be 8-20 characters long.\n';
    }
    if (!/[a-z]/.test(password)) {
      errorMessage += 'Password must include at least one lowercase letter.\n';
    }
    if (!/[A-Z]/.test(password)) {
      errorMessage += 'Password must include at least one uppercase letter.\n';
    }
    if (!/\d/.test(password)) {
      errorMessage += 'Password must include at least one number.\n';
    }
    if (!/[@$!%#*?&]/.test(password)) {
      errorMessage += 'Password must include at least one special character (@, $, !, %, *, ?, &).\n';
    }

    if (errorMessage) {
      showCustomAlert('Invalid Password', errorMessage);
      return false;
    }

    return true;
  };

  const isValidEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const checkUserExists = async () => {
    try {
      if(username){
        const userExistsResponse = await axios.get(`${API_URL}/users/exists/${username}`,
          {
            withCredentials: true,
          }
        )

        // Extracting the 'exists' value from the response
        return userExistsResponse.data.exists;
        
      } else {
        showCustomAlert("Blank Username", "Please enter a username and try again");
      }
      
    } catch (error: any) {
      console.error("Error encountered", error.response);
    }
  }

  const checkEmailExists = async () => {
    try {
      if(email){
        const emailExistsResponse = await axios.get(`${API_URL}/users/exists/${email}`,
          {
            withCredentials: true
          }
        )

        // Extracting the 'exists' value from the response
        return emailExistsResponse.data.exists;
        
      } else {
        showCustomAlert("Email in use", "Email in use, please enter another email or sign in.");
      }
      
    } catch (error: any) {
      console.error("Error encountered", error.response);
    }
  }
  
  const handleSignUp = async () => {
    try {

      var userCredential = null;

      try{
        userCredential = await createUserWithEmailAndPassword(firebase.auth, email, password);
        
      }
      catch(error: any){
        console.error("Error encountered", error.response);
      }

      if(userCredential){
        const response = await axios.post(`${API_URL}/users`, 
          {
            auth0Id: userCredential.user.uid,
            email: email,
            username: username,
            name: name,
            bio: bio,
          },
          {
            withCredentials: true
          }
        );
        if (response.data) {
          setUserProfile(response.data);
          setIsAuthenticated(true);
        }
      }
    } catch (error: string | any) {
      if (axios.isAxiosError(error)) {
        // Axios error
        console.error('Axios error:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          headers: error.response?.headers
        });
      } else {
        // Non-Axios error
        showCustomAlert('Error during sign up:', error);
      }
      showCustomAlert('Signup Error', 'There was an error signing up. Please try again.');
    }
  };

  return (
    <View style={styles.container}>

      <CustomAlert {...alertProps} />

      {step === 1 && (
        <View style={styles.inputContainer}>
          <View style={styles.headerContainer}>
            <View style={{right: '50%', marginBottom: 10}}>
              <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')}>
                <FontAwesomeIcon icon={faClose} color='white' size={36}></FontAwesomeIcon>
              </TouchableOpacity>
            </View>
            <Text style={styles.headerText}>Create username</Text>
            <Text style={styles.subHeaderText}>Choose a username for your account. This will be how other users see you.</Text>
          </View>
          <TextInput
            style={styles.input}
            value={username}
            onChangeText={(text) => setUsername(text.substring(0, 30))} // Limit to 30 characters
            placeholder="Create your username"
            placeholderTextColor="white"
            maxLength={30} // Maximum length
          />
        </View>
      )}

      {step === 2 && (
        <View style={styles.inputContainer}>
          <View style={styles.headerContainer}>
            <View style={{right: '50%', marginBottom: 10}}>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <FontAwesomeIcon icon={faClose} color='white' size={36}></FontAwesomeIcon>
              </TouchableOpacity>
            </View>
            <Text style={styles.headerText}>Enter Your Email</Text>
            <Text style={styles.subHeaderText}>This will be used to log into your account.</Text>
          </View>
          <TextInput
            style={styles.input}
            value={email}
            onChangeText={setEmail}
            placeholder="Enter your email"
            placeholderTextColor="white"
            keyboardType="email-address"
          />
        </View>
      )}

      {step === 3 && (
        <View style={styles.inputContainer}>
          <View style={styles.headerContainer}>
            <View style={{right: '50%', marginBottom: 10}}>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <FontAwesomeIcon icon={faClose} color='white' size={36}></FontAwesomeIcon>
              </TouchableOpacity>
            </View>
            <Text style={styles.headerText}>Create a Password</Text>
            <Text style={styles.subHeaderText}>Choose a strong password to protect your account.</Text>
          </View>
          <TextInput
            style={styles.input}
            value={password}
            onChangeText={setPassword}
            placeholder="Choose a password"
            placeholderTextColor="white"
            secureTextEntry
          />
        </View>
      )}

      {step === 4 && (
        <View style={styles.inputContainer}>
          <View style={styles.headerContainer}>
            <View style={{right: '50%', marginBottom: 10}}>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <FontAwesomeIcon icon={faClose} color='white' size={36}></FontAwesomeIcon>
              </TouchableOpacity>
            </View>
            <Text style={styles.headerText}>What's Your Name?</Text>
            <Text style={styles.subHeaderText}>Tell us your name so people know who you are.</Text>
          </View>
          <TextInput
            style={styles.input}
            value={name}
            onChangeText={setName}
            placeholder="Enter your name"
            placeholderTextColor="white"
          />
        </View>
      )}

      {step === 5 && (
        <View style={styles.inputContainer}>
          <View style={styles.headerContainer}>
            <View style={{right: '50%', marginBottom: 10}}>
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <FontAwesomeIcon icon={faClose} color='white' size={36}></FontAwesomeIcon>
              </TouchableOpacity>
            </View>
            <Text style={styles.headerText}>Add a Bio</Text>
            <Text style={styles.subHeaderText}>Share a little about yourself with the community.</Text>
          </View>
          <TextInput
            style={styles.input}
            value={bio}
            onChangeText={setBio}
            placeholder="Add a bio (optional)"
            placeholderTextColor="white"
          />
        </View>
      )}

      <View style={styles.button}>
        <Button color={'blue'} title={step === 5 ? "Sign Up" : "Next"} onPress={handleNextStep} />
      </View>
    </View>
  );
};

// Add styles for inputs and container



export default SignUpScreen;