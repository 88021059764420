// EditProfileModal.tsx
import React from 'react';
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  StyleSheet,
  Platform,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faCamera,
  faPencil,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Image from '../UtilityComponents/UniversalImage';

type EditProfileModalProps = {
  visible: boolean;
  onClose: () => void;
  profilePicture: any;
  loading: boolean;
  newBio: string;
  setNewBio: (bio: string) => void;
  updateBio: () => void;
  pickImageAndUpload: () => void;
  editBioMode: boolean;
  setEditBioMode: (value: boolean) => void;
  userProfile?: { bio?: string };
};

const MAX_BIO_LENGTH = 200;

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  visible,
  onClose,
  profilePicture,
  loading,
  newBio,
  setNewBio,
  updateBio,
  pickImageAndUpload,
  editBioMode,
  setEditBioMode,
  userProfile,
}) => {
  return (
    <Modal visible={visible} animationType="slide" transparent>
      <View style={styles.modalOverlay}>
        <View style={styles.modalContainer}>
          {/* Header */}
          <View style={styles.header}>
            <TouchableOpacity onPress={onClose}>
              <FontAwesomeIcon icon={faTimes} size={24} color="#fff" />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Edit Profile</Text>
            <View style={{ width: 24 }} />
          </View>

          <View style={styles.content}>
            {/* Profile Picture Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Profile Picture</Text>
              <View style={styles.profilePictureContainer}>
                <View style={styles.photoWrapper}>
                  {loading ? (
                    <ActivityIndicator size="large" color="#bbb" />
                  ) : (
                    <Image source={profilePicture} style={styles.avatar} />
                  )}
                </View>
                <TouchableOpacity
                  onPress={pickImageAndUpload}
                  style={styles.changePhotoButton}
                >
                  <Text style={styles.changePhotoButtonText}>Change Photo</Text>
                </TouchableOpacity>
              </View>
            </View>

            {/* Bio Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Bio</Text>
              <View style={styles.bioContainer}>
                {editBioMode ? (
                  <>
                    <View style={styles.bioInputWrapper}>
                      <TextInput
                        style={styles.bioInput}
                        onChangeText={setNewBio}
                        value={newBio}
                        placeholder={userProfile?.bio || 'Enter your bio'}
                        placeholderTextColor="#888"
                        multiline
                      />
                      <Text
                        style={[
                          styles.charCounter,
                          newBio.length > MAX_BIO_LENGTH && styles.charCounterError,
                        ]}
                      >
                        {newBio.length}/{MAX_BIO_LENGTH}
                        {newBio.length > MAX_BIO_LENGTH ? ' !' : ''}
                      </Text>
                    </View>
                    <TouchableOpacity onPress={updateBio} style={styles.saveButton}>
                      <Text style={styles.saveButtonText}>Save</Text>
                    </TouchableOpacity>
                  </>
                ) : (
                  <View style={styles.bioDisplay}>
                    <Text style={styles.bioText}>{userProfile?.bio}</Text>
                    <TouchableOpacity
                      onPress={() => setEditBioMode(true)}
                      style={styles.iconButton}
                    >
                      <FontAwesomeIcon icon={faPencil} size={20} color="#fff" />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    width: '90%',
    backgroundColor: '#2c2c2c',
    borderRadius: 12,
    padding: 20,
    shadowColor: '#000',
    shadowOpacity: 0.4,
    shadowRadius: 10,
    elevation: 8,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#fff',
  },
  content: {
    // Center the content and allow vertical spacing between sections
    width: '100%',
  },
  section: {
    marginBottom: 25,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: '#fff',
    marginBottom: 8,
  },
  /* Profile Picture Section */
  profilePictureContainer: {
    flexDirection: 'row',
    backgroundColor: '#262626',
    borderRadius: 8,
    alignItems: 'center',
    padding: 10,
  },
  photoWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  changePhotoButton: {
    backgroundColor: '#0094F7',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
  },
  changePhotoButtonText: {
    color: '#fff',
    fontWeight: '500',
  },
  /* Bio Section */
  bioContainer: {
    width: '100%',
  },
  bioDisplay: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3a3a3a',
    padding: 12,
    borderRadius: 8,
  },
  bioText: {
    flex: 1,
    fontSize: 16,
    color: '#fff',
  },
  bioInputWrapper: {
    position: 'relative',
    backgroundColor: '#3a3a3a',
    borderRadius: 8,
    padding: 12,
  },
  bioInput: {
    fontSize: 16,
    color: '#fff',
    paddingRight: 50, // extra space for the character counter
  },
  charCounter: {
    position: 'absolute',
    right: 12,
    bottom: 8,
    fontSize: 12,
    color: '#ccc',
  },
  charCounterError: {
    color: 'red',
  },
  saveButton: {
    backgroundColor: '#0094F7',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 6,
    alignSelf: 'flex-end',
    marginTop: 8,
  },
  saveButtonText: {
    color: '#fff',
    fontWeight: '500',
    fontSize: 16,
  },
  iconButton: {
    marginLeft: 12,
  },
});

export default EditProfileModal;
