// EndMessage.tsx
import React, { useRef, useEffect } from 'react';
import {
  Animated,
  StyleSheet,
  View,
  Text,
  Image,
  Easing,
  Dimensions,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

const wavingGif = require('../../assets/gifs/clipdle-waving.gif');

const EndMessage = () => {
  // Calculate container width (90% of screen width)
  const containerWidth = Dimensions.get('window').width * 0.9;

  // Animated value for horizontal translation
  const translateX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        // Animate the gradient leftwards by the extra width (containerWidth)
        Animated.timing(translateX, {
          toValue: -containerWidth,
          duration: 8000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        // Instantly reset to starting position for a seamless loop
        Animated.timing(translateX, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [translateX, containerWidth]);

  return (
    <View style={styles.container}>
      {/* Animated background container with horizontal translation */}
      <Animated.View
        style={[
          styles.animatedBackground,
          { transform: [{ translateX }] },
        ]}
      >
        <LinearGradient
          colors={['#FF073A', '#FF8C00', '#32CD32', '#00BFFF', '#FF00FF']}
          // Set a horizontal gradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          // Set gradient width to twice the container's width
          style={[styles.gradient, { width: containerWidth * 2 }]}
        />
      </Animated.View>

      {/* Content displayed on top of the animated gradient */}
      <View style={styles.content}>
        <Text style={styles.messageText}>
          That's it! Invite your friends for more content!
        </Text>
        <View style={styles.wavingGifContainer}>
          <Image source={wavingGif} style={styles.wavingGif} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    width: '90%',
    alignSelf: 'center',
    borderRadius: 15,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  animatedBackground: {
    ...StyleSheet.absoluteFillObject,
  },
  gradient: {
    height: '100%',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  messageText: {
    flex: 1,
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
    // Adding a text shadow to improve legibility
    textShadowColor: 'rgba(0, 0, 0, 0.8)',
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 4,
  },
  wavingGifContainer: {
    marginLeft: 10,
  },
  wavingGif: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
  },
});

export default EndMessage;
