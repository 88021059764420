// CompactLoginModal.tsx
import React, { useState } from 'react';
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  TextInput,
  Image,
} from 'react-native';
import { useAuthentication } from '../../hooks/user/useAuthentication'; // same hook used in Login.tsx
import CustomAlert from '../Hooks/Alert/Alert';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../linkingConfig';

// You can reuse your existing GIF or images
const clipdleGif = require('../../assets/gifs/clipdle-thinking.gif');

interface CompactLoginModalProps {
  visible: boolean;
  onClose: () => void;    // callback to close the modal
  onLoginSuccess?: () => void; // optional: do something after successful login
}

const CompactLoginModal: React.FC<CompactLoginModalProps> = ({
  visible,
  onClose,
  onLoginSuccess,
}) => {

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  // Local state for credentials
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // We reuse the login logic from useAuthentication
  const {
    loading,
    handleLogin,
    alertVisible,
    setAlertVisible,
    alertTitle,
    alertMessage,
  } = useAuthentication();

  // You can call onClose and onLoginSuccess after successful login
  const attemptLogin = async () => {
    const success = await handleLogin(email, password);
    if (success) {
      onClose();
      if (onLoginSuccess) {
        onLoginSuccess();
      }
    }
  };

  const navigateToAppropriateScreen = (subscreen: string) => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'LoginNavigatorScreen',
            state: {
              routes: [
                {
                  name: subscreen
                },
              ],
            },
          },
        ],
      })
    );
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="slide"
      onRequestClose={onClose}
    >
      {/* A semi-transparent background */}
      <View style={styles.modalBackground}>
        {/* The actual content container */}
        <View style={styles.modalContainer}>

          {/* Reuse the same alerts */}
          <CustomAlert
            title={alertTitle}
            message={alertMessage}
            isVisible={alertVisible}
            onClose={() => setAlertVisible(false)}
            buttons={[
              {
                text: 'OK',
                onPress: () => setAlertVisible(false),
              },
            ]}
          />

          {/* Title area */}
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Clipdle</Text>
            <Image source={clipdleGif} style={styles.gifStyle} />
          </View>
          <Text style={styles.slogan}>
            Not Just Watching – Question, Answer, and Connect!
          </Text>

          {/* Email & Password inputs */}
          <TextInput
            style={styles.input}
            onChangeText={setEmail}
            value={email}
            placeholder="Email"
            placeholderTextColor={'black'}
            keyboardType="email-address"
            autoCapitalize="none"
          />
          <TextInput
            style={styles.input}
            onChangeText={setPassword}
            value={password}
            placeholder="Password"
            placeholderTextColor={'black'}
            secureTextEntry
          />

          {/* Login Button */}
          <TouchableOpacity
            style={styles.loginButton}
            onPress={attemptLogin}
            disabled={loading}
          >
            {loading ? (
              <ActivityIndicator size="small" color="#FFFFFF" />
            ) : (
              <Text style={styles.buttonText}>Log In</Text>
            )}
          </TouchableOpacity>

          {/* Optional: you can remove or hide "Forgot Password" / "Sign Up" links */}
          <Text style={styles.forgotPasswordText} onPress={() => navigateToAppropriateScreen('ForgotPassword')}>Forgot your password?</Text>
          <Text style={styles.signUpText}>
            Don&apos;t have an account? <Text style={styles.signUpLink} onPress={() => navigateToAppropriateScreen('SignUpScreen')}>Sign Up</Text>
          </Text>

          {/* Close button (X) or "Cancel" */}
          <TouchableOpacity onPress={onClose} style={styles.closeButton}>
            <Text style={styles.closeButtonText}>CANCEL</Text>
          </TouchableOpacity>

        </View>
      </View>
    </Modal>
  );
};

export default CompactLoginModal;

const styles = StyleSheet.create({
  /* Semi-transparent dark background */
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  /* Container for the modal itself */
  modalContainer: {
    width: '85%',         // smaller width
    maxWidth: 400,        // optional maximum
    backgroundColor: 'black',
    borderRadius: 10,
    alignItems: 'center',
    padding: 20,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    borderRadius: 20,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#2069e0',
  },
  title: {
    fontSize: 36,  // smaller title compared to 48
    color: '#f4d47c',
    fontWeight: 'bold',
  },
  gifStyle: {
    width: 40,   // smaller
    height: 40,  // smaller
    marginLeft: 10,
  },
  slogan: {
    fontSize: 14,
    maxWidth: '90%',
    color: '#bebebe',
    fontStyle: 'italic',
    marginBottom: 30,
    marginTop: 10,
    textAlign: 'center',
  },
  input: {
    width: '90%',
    maxWidth: 300,
    padding: 10,
    marginVertical: 5,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  loginButton: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: '#2069e0',
    borderRadius: 30,
    marginTop: 20,
  },
  buttonText: {
    fontSize: 16,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  forgotPasswordText: {
    color: '#f4d47c',
    fontSize: 14,
    paddingBottom: 10,
    textDecorationLine: 'underline',
    marginVertical: 10,
  },
  signUpText: {
    fontSize: 14,
    color: '#878683',
    marginBottom: 20,
  },
  signUpLink: {
    color: '#f4d47c',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  closeButton: {
    marginTop: 10,
    paddingHorizontal: 10,
    paddingVertical: 8,
    backgroundColor: '#555', 
    borderRadius: 5,
  },
  closeButtonText: {
    color: 'white',
  },
});
