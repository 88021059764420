import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  View,
  StatusBar,
  FlatList,
  Dimensions,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  Text,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import QuizOverlay from '../QuizOverlay/QuizOverlay';
import { useAuth } from '../../context/AuthContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { faRepeat, faPlay } from '@fortawesome/free-solid-svg-icons';
import { ViewStyle } from 'react-native';
import { useSharedValue, withTiming } from 'react-native-reanimated';
import styles from './style';
import { debounce } from '../../hooks/utilities/useDebounce';
import { LinearGradient } from 'expo-linear-gradient';
import EndMessage from '../EndMessage/EndMessage';
import {
  ProgressData,
  QuizProps,
  VideoResponseData,
  VideoOrEndMessage,
  VideoData,
  ExtendedViewToken,
  WatchedVideoData,
} from './QuizTypes';
import { API_URL } from '@env';
import { getAuth } from 'firebase/auth';
import { Audio, InterruptionModeIOS, InterruptionModeAndroid } from 'expo-av';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const Quiz: React.FC<QuizProps> = () => {
  /****************************************
   *  State
   ****************************************/
  const [videos, setVideos] = useState<VideoOrEndMessage[]>([]);
  const videosRef = useRef<VideoOrEndMessage[]>([]); // <-- We'll store the array in a ref

  // Whenever `videos` changes, copy it into `videosRef.current`
  useEffect(() => {
    videosRef.current = videos;
  }, [videos]);

  const [isLoading, setIsLoading] = useState(true);
  const [numVideosWatched, setNumVideosWatched] = useState(0);
  const [page, setPage] = useState(1);
  const [lastVideoId, setLastVideoId] = useState<string | null>(null);

  const questionAnswered = useRef<boolean>(false);
  const currentVideoRef = useRef<VideoData | null>(null);

  const [watchedVideos, setWatchedVideos] = useState<WatchedVideoData[]>([]);

  // Using refs because synchronous updates are needed
  const videoWatchTimesRef = useRef<Record<string, number>>({});
  const videoInteractionRef = useRef<Record<string, Partial<WatchedVideoData>>>({});

  const [currentVideoWatchTime, setCurrentVideoWatchTime] = useState(0.0);

  const [showReplayButton, setShowReplayButton] = useState(false);
  const [loadedVideoIndices, setLoadedVideoIndices] = useState<number[]>([]);
  const [allVideosWatched, setAllVideosWatched] = useState(false);
  const [isReportCheckLoading, setIsReportCheckLoading] = useState(true);
  const [blockedList, setBlockedList] = useState<string[]>([]);
  const [showPlayButton, setShowPlayButton] = useState(false);

  const { userProfile, updateWatchedVideos: updateWatchedProfileVideos, getToken } = useAuth();

  // Refs
  const previousVideoIdRef = useRef<string | null>(null);
  const justLeftVideoRef = useRef<VideoData | null>(null);
  const videoRefs = useRef<Map<string, Video>>(new Map());

  // Layout
  const insets = useSafeAreaInsets();
  const bottomInset = insets.bottom || 0;
  const isFocused = useIsFocused();

  const WINDOW_HEIGHT =
    Platform.OS === 'android' && Platform.Version >= 29
      ? Dimensions.get('window').height + (StatusBar.currentHeight || 0)
      : Dimensions.get('window').height;

  const tabBarHeight = 55;
  const availableHeight = WINDOW_HEIGHT - (StatusBar.currentHeight || 0) - tabBarHeight - bottomInset;
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const videoAspectRatio = 9 / 16;
  const videoWidth = Math.min(windowWidth, windowHeight * videoAspectRatio);
  const videoHeight = videoWidth / videoAspectRatio;

  const dynamicStyles: { videoContainer: ViewStyle; fullScreenVideo: ViewStyle } = {
    videoContainer: {
      width: undefined,
      height: availableHeight,
      justifyContent: 'center',
      alignItems: 'center',
    },
    fullScreenVideo: {
      aspectRatio: videoAspectRatio,
      width: videoWidth,
      height: videoHeight,
      alignSelf: 'center',
    },
  };

  /****************************************
   *  Helpers
   ****************************************/
  function getVideoStyle(aspectRatioString: string) {
    const [widthStr, heightStr] = aspectRatioString.split(':');
    const width = parseInt(widthStr, 10);
    const height = parseInt(heightStr, 10);
    const ratio = width / height;

    const isMobileFormat = height > width;
    if (!isMobileFormat) {
      return {
        width: '100%',
        height: '100%',
        alignSelf: 'center' as const,
      };
    } else {
      const vidWidth = Math.min(windowWidth, windowHeight * ratio);
      const vidHeight = vidWidth / ratio;
      return {
        aspectRatio: ratio,
        width: vidWidth,
        height: vidHeight,
        alignSelf: 'center' as const,
      };
    }
  }

  function setVideoRef(ref: Video | null, videoId: string) {
    if (ref) {
      videoRefs.current.set(videoId, ref);
    } else {
      videoRefs.current.delete(videoId);
    }
  }

  async function checkVideoReportedStatus(videoId: string) {
    setIsReportCheckLoading(true);
    try {
      await axios.get(`${API_URL}/videos/${videoId}/isReportedOther`, {
        withCredentials: true,
      });
    } catch (error) {
      console.error('Error checking video reported status:', error);
    } finally {
      setIsReportCheckLoading(false);
    }
  }

  // A helper to capture user interactions from QuizOverlay
  const handleVideoInteraction = useCallback(
    (videoId: string, updates: Partial<WatchedVideoData>) => {
      // Merge new updates with what we have
      videoInteractionRef.current[videoId] = {
        ...videoInteractionRef.current[videoId],
        ...updates,
      };
    },
    []
  );

  /****************************************
   *  updateWatchedVideos
   ****************************************/
  const buildWatchedVideoObject = useCallback(
    (video: VideoData): WatchedVideoData => {

      const watchTime = videoWatchTimesRef.current[video.id] ?? 0;

      // 2) Also get any user interactions from `videoInteractionRef`
      const interactionData = videoInteractionRef.current[video.id] ?? {};

       // Now fill in fields, defaulting to false if missing
      return {
        videoId: video.id,
        watchTime,

        didLike: interactionData.didLike ?? false,
        didDislike: interactionData.didDislike ?? false,
        didComment: interactionData.didComment ?? false,
        didAnswer: interactionData.didAnswer ?? false,
        userAnswerIndex: interactionData.userAnswerIndex ?? null,
        answeredCorrectly: interactionData.answeredCorrectly ?? false,

        uploaderUserId: video.userId,
        tags: video.tags || [],
        question: video.question || null,
        answers: video.answers || [],
        correctAnswer: typeof video.correctAnswer === 'number' ? video.correctAnswer : null,
        likes: video.likes || 0,
        dislikes: video.dislikes || 0,
        aspectRatio: video.aspectRatio || '9:16',
        commentsCount: video.comments ? video.comments.length : 0,
        watchedAt: new Date(),
      };
    },
    [currentVideoWatchTime]
  );

  const updateWatchedVideos = useCallback(
    async (oldVideoId: string) => {
      // Instead of relying on `videos` state, we rely on justLeftVideoRef
      const oldVideoData = justLeftVideoRef.current;
      if (!oldVideoData) return;

      if (watchedVideos.some((wv) => wv.videoId === oldVideoId)) return;

      const watchedVideoObj = buildWatchedVideoObject(oldVideoData);
      const updated = [...watchedVideos, watchedVideoObj];
      setWatchedVideos(updated);

      updateWatchedProfileVideos(watchedVideos);

      await AsyncStorage.setItem('watchedVideos', JSON.stringify(updated));

      try {
        const url = `${API_URL}/users/${userProfile?.username}/watchVideo/${oldVideoId}`;
        await axios.patch(url, 
          {
            watchedVideoObj,
          },
          {
            withCredentials: true,
          }
        );
      } catch (err) {
        console.error('Error saving watched videos:', err);
      }
    },
    [watchedVideos, buildWatchedVideoObject, userProfile, updateWatchedProfileVideos, getToken]
  );

  /****************************************
   *  handleViewableItemsChanged
   ****************************************/
  const handleViewableItemsChanged = useCallback(
    (info: { viewableItems: ExtendedViewToken[]; changed: ExtendedViewToken[] }) => {
      const { viewableItems } = info;
      setNumVideosWatched((count) => count + 1);

      if (viewableItems.length > 0) {
        const firstViewableItem = viewableItems[0].item as VideoData;
        const nextVideoId = firstViewableItem.id;

        if (previousVideoIdRef.current && previousVideoIdRef.current !== nextVideoId) {
          // old video ID
          const oldVideoId = previousVideoIdRef.current;

          // Instead of `videos.find(...)`, we do:
          // Because we store the array in videosRef to ensure it’s up-to-date
          const oldVideoData = videosRef.current.find((v) => v.id === oldVideoId);

          if (oldVideoData && !('isEndMessage' in oldVideoData)) {
            justLeftVideoRef.current = oldVideoData;
          } else {
            justLeftVideoRef.current = null;
          }

          // If not recorded yet, record now
          if (!watchedVideos.some((wv) => wv.videoId === oldVideoId)) {
            updateWatchedVideos(oldVideoId);
          }
        }

        previousVideoIdRef.current = nextVideoId;
        // setCurrentVideo(firstViewableItem);

        currentVideoRef.current = firstViewableItem;
        
        setLastVideoId(nextVideoId);
        setShowReplayButton(false);
        questionAnswered.current = false;

        if (firstViewableItem) {
          checkVideoReportedStatus(nextVideoId);
        }

        const indices = viewableItems
          .map((viewable) => viewable.index)
          .filter((i): i is number => i !== null);
        setLoadedVideoIndices(indices);
      } else {
        setLoadedVideoIndices([]);
      }
      setCurrentVideoWatchTime(0.0);
    },
    [watchedVideos, updateWatchedVideos, checkVideoReportedStatus]
  );

  /****************************************
   *  viewabilityConfigCallbackPairs
   ****************************************/
  const viewabilityConfig = { itemVisiblePercentThreshold: 50 };
  const viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged: handleViewableItemsChanged },
  ]);

  /****************************************
   *  Fetched videos
   ****************************************/
  const debouncedFetchVideos = useCallback(
    debounce(async () => {
      try {
        const followingUserIds = userProfile?.followingUserIds;
        const blockedIds = userProfile?.blockedUserIds;
        if (blockedIds) setBlockedList(blockedIds);

        let watchedVideoIds: string[] = [];
        if (Array.isArray(userProfile?.watchedVideos)) {
          watchedVideoIds = userProfile!.watchedVideos.map((item) => item.videoId);
        }

        const response = await axios.get(
          `${API_URL}/videos?` +
            `followingUserIds=${JSON.stringify(followingUserIds)}` +
            `&watchedVideos=${JSON.stringify(watchedVideoIds)}` +
            `&lastId=${lastVideoId}` +
            `&blockedUserIds=${JSON.stringify(blockedIds)}` +
            `&page=${page}`,
          {
            withCredentials: true,
          }
        );

        const data: VideoResponseData[] = await response.data;
        
        if (data.length > 0) {
          setLastVideoId(data[data.length - 1].id);
          setAllVideosWatched(false);
        } else if (data.length === 0 && !allVideosWatched) {
          setAllVideosWatched(true);
        }

        setVideos((prevVideos) => {
          const newVideos = data.filter(
            (video) => !prevVideos.some((existingVideo) => existingVideo.id === video.id)
          );
          return [...prevVideos, ...newVideos];
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch or parse videos:', error);
      }
    }, 600),
    [userProfile?.followingUserIds, userProfile?.blockedUserIds, userProfile?.watchedVideos, lastVideoId]
  );

  const handleOnEndReached = () => {
    setPage((prev) => prev + 1);
    debouncedFetchVideos();
  }

  // Possibly fetch on mount
  useEffect(() => {
    if (!userProfile || !userProfile.username) return;
  
    debouncedFetchVideos();
  }, [userProfile, debouncedFetchVideos]);

  useEffect(() => {
    if (!userProfile || !userProfile.username) return;
    
    if (numVideosWatched % 2 === 0 && numVideosWatched > 0) {
      debouncedFetchVideos();
    }
  }, [numVideosWatched, debouncedFetchVideos]);

  // Show a "fade in" end message if videos is empty
  const opacity = useSharedValue(0);

  useEffect(() => {
    (async () => {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: InterruptionModeIOS.DoNotMix,
        playsInSilentModeIOS: true,
        interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
        shouldDuckAndroid: true,
        staysActiveInBackground: false,
      });
    })();
  }, []);

  /****************************************
   *  onProgress
   ****************************************/
  const onProgress = useCallback(
    (data: ProgressData, videoId: string) => {

      if (currentVideoRef.current && data.currentTime >= (currentVideoRef.current.pausePoint ?? 999999) && !questionAnswered.current) {
        const videoRef = videoRefs.current.get(videoId);
        if (videoRef) {
          videoRef.pauseAsync();
        }
        setShowReplayButton(true);
      }

      // track watch time in dictionary
      if (data.currentTime > (videoWatchTimesRef.current[videoId] ?? 0)) {
        videoWatchTimesRef.current[videoId] = data.currentTime;
      }
    },
    []
  );

  /****************************************
   *  handleOnPlaybackStatusUpdate
   ****************************************/
  const handleOnPlaybackStatusUpdate = useCallback(
    (videoId: string) => (status: AVPlaybackStatus) => {

      if (!status.isLoaded) {
        setShowPlayButton(true);
      } else if (status.isPlaying) {

        const currentTimeSec = status.positionMillis / 1000;
        onProgress({ currentTime: currentTimeSec }, videoId);
        setShowPlayButton(false);

      }
    },
    [onProgress]
  );

  /****************************************
   *  Replay & Play
   ****************************************/
  const handleReplay = () => {
    if (!currentVideoRef.current?.id) return;
    const player = videoRefs.current.get(currentVideoRef.current.id);
    if (player) {
      player.setPositionAsync(0).then(() => {
        player.playAsync();
      });
    }
    setShowReplayButton(false);
  };

  const ReplayButton = () => (
    <TouchableOpacity style={styles.replayButtonContainer} onPress={handleReplay}>
      <FontAwesomeIcon icon={faRepeat} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  const handlePlay = () => {
    if (!currentVideoRef.current?.id) return;
    const player = videoRefs.current.get(currentVideoRef.current.id);
    if (player) {
      player.playAsync().then(() => setShowPlayButton(false));
    }
  };

  const PlayButton = () => (
    <TouchableOpacity style={styles.playButtonContainer} onPress={handlePlay}>
      <FontAwesomeIcon icon={faPlay} size={50} color="#FFF" />
    </TouchableOpacity>
  );

  /****************************************
   *  handleAnswerSelect
   ****************************************/
  const handleAnswerSelect = (
    answer: string,
    index: number,
    isCorrect: boolean,
    videoItem: VideoData,
  ) => {
    questionAnswered.current = true;
    setShowReplayButton(false);

    if (!currentVideoRef.current?.id) return;
    const videoRef = videoRefs.current.get(currentVideoRef.current.id);
    if (videoRef && isFocused) {
      try {
        videoRef.playAsync();
      } catch(e) {
        console.error('Error calling playAsync:', e);
      }
    }
  };

  /****************************************
   *  Auto-play / pause on focus
   ****************************************/
  useEffect(() => {
    if (!currentVideoRef.current?.id) return;
    const videoRef = videoRefs.current.get(currentVideoRef.current.id);
    if (videoRef) {
      if (isFocused) {
        videoRef.playAsync();
      } else {
        videoRef.pauseAsync();
      }
    }
  }, [isFocused, currentVideoRef.current]);

  /****************************************
   *  FlatList
   ****************************************/
  const keyExtractor = (item: VideoOrEndMessage) => item.id;

  function getItemLayout(data: ArrayLike<VideoOrEndMessage> | null | undefined, index: number) {
    if (data && 'isEndMessage' in data[index]) {
      return { length: 100, offset: 100 * index, index };
    }
    return { length: availableHeight, offset: availableHeight * index, index };
  }

  function renderItem({ item, index }: { item: VideoOrEndMessage; index: number }) {
    if ('isEndMessage' in item) {
      // Use a linear gradient background for a modern look
      return (
        <LinearGradient
          colors={['#141E30', '#243B55']} // Feel free to tweak these colors
          style={styles.endMessageContainer}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
        >
          <Text style={styles.endMessageText}>
            That's it! Invite your friends for more content!
          </Text>
        </LinearGradient>
      );
    }
    const videoStyle = getVideoStyle(item.aspectRatio || '9:16');

    return (
      <View style={dynamicStyles.videoContainer}>
        {isReportCheckLoading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator testID="loading-indicator" size="large" color="#00ff00" />
          </View>
        )}

        {!isReportCheckLoading && loadedVideoIndices.includes(index) && (
          <Video
            source={{ uri: item.source }}
            style={videoStyle as ViewStyle}
            videoStyle={videoStyle as ViewStyle}
            isLooping
            resizeMode={ResizeMode.COVER}
            shouldPlay
            ref={(ref) => setVideoRef(ref, item.id)}
            onPlaybackStatusUpdate={handleOnPlaybackStatusUpdate(item.id)}
          />
        )}

        {isLoading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#00ff00" />
          </View>
        )}

        {showReplayButton && <ReplayButton />}
        {showPlayButton && <PlayButton />}

        <QuizOverlay
          mongoId={item._id}
          question={item.question as string}
          GCSId={item.id}
          answers={item.answers || []}
          isBlocked={!!item.uploaderUsername && blockedList.includes(item.uploaderUsername)}
          onAnswerSelect={(ans, idx, correct) =>
            handleAnswerSelect(ans, idx, correct, item)
          }
          correctAnswer={item.correctAnswer}
          uploaderUsername={item.uploaderUsername}
          isFromProfile={false}
          onVideoInteraction={(updates) => handleVideoInteraction(item.id, updates)}
        />
      </View>
    );
  }

  // Optionally, if you want to show the loading indicator for the entire screen when isLoading is true:
  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <View style={styles.quizContainer}>
    <FlatList
      data={videos}
      windowSize={4}
      initialNumToRender={0}
      maxToRenderPerBatch={2}
      removeClippedSubviews
      testID="videoList"
      onEndReached={handleOnEndReached}
      onEndReachedThreshold={0}
      pagingEnabled
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsVerticalScrollIndicator={false}
      viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
      getItemLayout={getItemLayout}
      decelerationRate="normal"
      ListFooterComponent={
        allVideosWatched ? (
          // This wrapper uses the computed availableHeight so that the EndMessage is centered vertically.
          <View
            style={{
              height: availableHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EndMessage />
          </View>
        ) : null
      }
    />
  </View>
  );
};

export default Quiz;
