// App.tsx
import React, { useEffect, useRef, useState } from 'react';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import MainTabNavigator from './navigators/MainTabNavigator';
import LoginNavigator from './navigators/LoginNavigator';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthProvider, useAuth } from './context/AuthContext';
import getUserProfile from './context/AuthContext';
import { Provider as PaperProvider } from 'react-native-paper';
import { linking } from './linkingConfig'
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { VideoProvider } from './context/VideoContext';
import { DeepLinkProvider, useDeepLink } from './context/DeepLinkContext';
import * as Linking from 'expo-linking';
import { LogBox } from 'react-native';
import firebase from './firebase';
import { onIdTokenChanged } from "firebase/auth";
import { RefetchProvider } from './context/RefetchContext';
import Admin from './components/Admin/Admin';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from './hooks/user/UserTypes';
import PublicVideoScreen from './components/PublicVideoScreen/PublicVideoScreen';
import type { RootStackParamList } from './linkingConfig';
import LauncherScreen from './components/LauncherScreen/LauncherScreen';
import { API_URL } from '@env';
import axios from 'axios';

LogBox.ignoreLogs(['StyleSheet.compose(a, b) is deprecated']);

const RootStack = createStackNavigator<RootStackParamList>();

// Save the original console functions
const originalConsoleWarn = console.warn;
const originalConsoleError = console.error;

// Override console.warn to filter out specific warnings
console.warn = (...args) => {
  const message = args[0] || "";
  // List of patterns to ignore
  const ignorePatterns = [
    "transform style array value is deprecated",
    "shadow* style props are deprecated",
    "textAlignVertical style is deprecated",
    "pointerEvents is deprecated",
    "accessibilityRole is deprecated",
    "accessibilityDisabled is deprecated",
    "focusable is deprecated",
    "TouchableOpacity is deprecated", 
    "Button is deprecated",
    "Cannot record touch end without a touch start",
    "* style array value is deprecated", 
    "Image: style.resizeMode is deprecated", 
    "Animated: `useNativeDriver` is not supported",
    "accessibilityLiveRegion is deprecated",
    "accessibilityComponentType is deprecated",
    "accessibilityTraits is deprecated",
    "accessibilityViewIsModal is deprecated",
    "accessibilityElementsHidden is deprecated",
    "accessibilityIgnoresInvertColors is deprecated",
    "accessibilityHint is deprecated",
    "accessibilityLabel is deprecated",
    "accessibilityLanguage is deprecated",
    "selectable prop is deprecated",
  ];

  // Check if the message matches any ignore patterns
  const shouldIgnore = ignorePatterns.some(pattern => message.includes(pattern));

  if (!shouldIgnore) {
    originalConsoleWarn.apply(console, args);
  }
};

console.error = (...args) => {
  if (/Unexpected text node/.test(args[0])) 
  {
    // Do nothing or handle the case specifically.
  } else if (/TouchableOpacity is deprecated/.test(args[0]))
  {

  } else {
    originalConsoleError.apply(console, args);
  }
};

const App = () => {
  const { isAuthenticated, setIsAuthenticated, setUserProfile, userProfile } = useAuth();
  const { deepLinkUrl, setDeepLinkUrl } = useDeepLink();
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminModalVisible, setAdminModalVisible] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = 'Clipdle';
    }
  }, []);

  const checkToken = async () => {
    setIsLoading(true); // Start loading
    const userProfileString = await AsyncStorage.getItem('userProfile');
    if (userProfileString) {
      const userProfile: User = JSON.parse(userProfileString);
      setUserProfile(userProfile);
      setIsAuthenticated(true);
    }
    else{
      setIsAuthenticated(false);
    }
    setIsLoading(false); // Stop loading once token check is complete
  };

  // Example function to determine if the user is an admin
  const checkIfAdmin = async () => {
    try {
      // Call the secure backend endpoint.
      const response = await axios.get(`${API_URL}/admin/check`,
        {
          withCredentials: true,
        }
      );
      const data = response.data;
      if (data) {
        setIsAdmin(data.isAdmin);
      } else {
        // If the request is not OK, assume not admin.
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Error checking admin status:", error);
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkIfAdmin();
    }
  }, [isAuthenticated])

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebase.auth, async (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const expiresIn = new Date(idTokenResult.expirationTime).getTime();
          const tokenDetails = JSON.stringify({
            token: idTokenResult.token,
            expiresIn,
          });
        });
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    checkToken();
  }, [deepLinkUrl]);

  useEffect(() => {
    const handleKeyDown = (event: { ctrlKey: any; altKey: any; key: string; }) => {
      if (event.ctrlKey && event.altKey && event.key === 'a') {  
        checkIfAdmin();
        setAdminModalVisible(!adminModalVisible);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (isLoading) {
    return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Text>Loading...</Text></View>;
  }

  const queryClient = new QueryClient();

  return (
    <DeepLinkProvider>
      <RefetchProvider>
        <QueryClientProvider client={queryClient}>
          <View style={{ flex: 1, width: '100%', backgroundColor: 'black' }}>
              <PaperProvider>
                <VideoProvider>
                  <NavigationContainer 
                    // linking={linking} 
                    ref={navigationRef}
                    documentTitle={{
                      enabled: true,
                      formatter: () => 'Clipdle',
                    }}
                    onStateChange={() => {
                      // Force the document.title to remain "Clipdle".
                      document.title = 'Clipdle';
                    }}
                  >
                    
                    <RootStack.Navigator
                      screenOptions={{ headerShown: false }}
                      initialRouteName="LauncherScreen"
                    >
                      {/* 1. The quick redirect screen */}
                      <RootStack.Screen 
                        name="LauncherScreen" 
                        component={LauncherScreen} 
                      />

                      {isAuthenticated ? (
                        // If authenticated, show the main tabs
                        <RootStack.Screen name="MainTab" component={MainTabNavigator} />
                      ) : (
                        <RootStack.Screen
                          name="LoginNavigatorScreen"
                          component={LoginNavigator}
                        />
                      )}

                      {!isAuthenticated && (
                        <RootStack.Screen
                          name="PublicVideoScreen"
                          component={PublicVideoScreen}
                        />
                      )}
                      
                    </RootStack.Navigator>

                    {isAdmin && <Admin visible={adminModalVisible} onClose={() => setAdminModalVisible(false)} />}
                  </NavigationContainer>
                </VideoProvider>
              </PaperProvider>
          </View>
        </QueryClientProvider>
      </RefetchProvider>
    </DeepLinkProvider>
  );
};

const AppWrapper = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWrapper;
