import React, { useState, useEffect, useRef } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import { View, Text, Button, StyleSheet, TouchableOpacity, Alert, Dimensions, Platform, Image } from 'react-native';
import SelectClipTimestamp from '../SelectClipTimestamp/SelectClipTimestamp';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlayCircle, faPauseCircle, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { ActivityIndicator } from 'react-native';
const wavingGif = require('../../assets/gifs/clipdle-waving.gif')
import { v4 as uuidv4 } from 'uuid';
import QuestionFormModal from '../QuestionForm/QuestionForm';
import styles from './style';

type SubmitClipScreenNavigationProp = StackNavigationProp<StackParamList, 'SubmitClip'>;

const SubmitClip: React.FC = () => {

  const navigation = useNavigation<SubmitClipScreenNavigationProp>();

  const [submitted, setSubmitted] = useState(false);
  const [videoFinished, setVideoFinished] = useState(false);
  const [videoSource, setVideoSource] = useState<string | null>(null);
  const [pausePoint, setPausePoint] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [currentPlaybackTime, setCurrentPlaybackTime] = useState<number>(0);
  const [justSeeked, setJustSeeked] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(true);
  const [videoDimensions, setVideoDimensions] = useState({ width: 1080, height: 1920 });
  const [modalVisible, setModalVisible] = useState(false);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [hasBeenPlayed, setHasBeenPlayed] = useState(false);

  const filename = uuidv4();

  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

  const videoRef = useRef<Video>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const playPauseIcon = isPaused ? faPlayCircle : faPauseCircle;

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
        const file = files[0];
        if (file.type.startsWith('video/')) {
            setVideoFile(file);
            setVideoSource(URL.createObjectURL(file));
        } else {
            alert('Please upload a valid video file');
        }
    }
  };

  const handleShowQuestionForm = () => {
    if (videoSource) {
      // Instead of setModalVisible(true):
      navigation.navigate('QuestionForm', {
        pausePoint,
        videoWidth: videoDimensions.width,
        videoHeight: videoDimensions.height,
        filename,
        file: videoFile, 
      });
    } else {
      console.error('No video selected', 'Please select a video first.');
    }
  };

  const togglePlayPause = () => {
    if (!hasBeenPlayed) {
      setHasBeenPlayed(true); // Set to true on the first play
    }
    setIsPaused(!isPaused);
  };

  const handleShowModal = () => {
    if (videoSource) { // Check if a video has been selected
        setModalVisible(true);
    } else {
        Alert.alert("No video selected", "Please select a video first.");
    }
};

  const maxVideoHeight = Dimensions.get('window').height * 0.6; // Adjust this factor as needed

  // Calculate aspect ratio of the video
  const aspectRatio = videoDimensions.width / videoDimensions.height;

  // Calculate height based on the aspect ratio and screen width
  const calculatedHeight = Dimensions.get('window').width / aspectRatio;

  let adjustedHeight = calculatedHeight;
  let adjustedWidth = Dimensions.get('window').width;

  if (calculatedHeight > maxVideoHeight) {
      adjustedHeight = maxVideoHeight;
      adjustedWidth = adjustedHeight * aspectRatio;
  }

  const dynamicVideoStyle = StyleSheet.create({
      videoTouchable: {
          width: adjustedWidth,
          height: adjustedHeight,
      },
  });

  useEffect(() => {
    if (videoRef.current) {
      if(isPaused){
        videoRef.current.pauseAsync();
      } else if (!isPaused){
        videoRef.current.playAsync();
      }
    }
  }, [isPaused, videoRef.current]);

  const handlePlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
        if (status.durationMillis && status.durationMillis > 100) { // Check for a minimum duration
            setVideoDuration(status.durationMillis / 1000); // Convert duration from milliseconds to seconds
        }

        if (status.isPlaying) {
            setPausePoint(status.positionMillis / 1000);
            setCurrentPlaybackTime(status.positionMillis / 1000);
            if (status.positionMillis / 1000 >= videoDuration && !videoFinished) {
                setVideoFinished(true);
            }
        }
    }
};

  // useEffect to log videoDuration when it changes
  useEffect(() => {
    
  }, [videoDuration]);
  

  const renderButton = (title: string, onPress: () => void) => {
    let color: string;

    if(Platform.OS === 'ios'){
      color = 'white';
    } else {
      color = 'blue';
    }

    const button = (
      <Button title={title} color={color} onPress={onPress} />
    );

    // Wrap the button in a view for iOS
    if (Platform.OS === 'ios') {
      return <View style={styles.chooseVideo}>{button}</View>;
    } else {
      // Directly return the button for Android
      return button;
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    setIsVideoPlaying(isFocused);
    if ( videoRef.current ) {
      if (isFocused) {
        videoRef.current.playAsync();
      } else {
        videoRef.current.pauseAsync();
      }
    }
  }, [isFocused]);

  return (
    <View style={{backgroundColor: 'black', height: '100%'}}>
      <View style={{height: '100%', maxHeight: '100%'}}>
        {!isVideoLoaded && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator style={styles.loadingContainer} size="large" color="#00ff00"></ActivityIndicator>
          </View>
        )}
        <View style={styles.container}>
          {submitted ? (
            <Text>Thank you! Your form submission has been received.</Text>
          ) : (
            <>
              {Platform.OS === 'web' && (
                <input
                  type="file"
                  accept="video/mp4"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileSelect}
                />
              )}

              {/* Replace the existing button logic with this */}
              {!videoSource && (
                <Button
                  title="Choose Video"
                  onPress={() => fileInputRef.current?.click()} // Trigger file input on button click
                />
              )}

              {videoSource && (
                <TouchableOpacity onPress={() => setVideoSource(null)} style={styles.backButton}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white"/>
                </TouchableOpacity>
              )}

              {!videoSource && ( // Conditional rendering based on videoSource
                <View style={styles.centeredContent}>
                  <View style={styles.wavingGifContainer}>
                    <Image source={wavingGif} style={styles.wavingGif} />
                  </View>
                  <Text style={styles.centeredText}>Select a video to get started</Text>
                  <Text style={styles.centeredText}>Videos should be 3-60 seconds long :)</Text>
                </View>
              )}

              {videoSource && isVideoLoaded && (
                <>
                  <View style={styles.videoContainer}>
                    <TouchableOpacity 
                      onPress={togglePlayPause}
                      style={dynamicVideoStyle.videoTouchable}
                    >
                      <Video
                        ref={videoRef}
                        source={{ uri: videoSource }}
                        style={dynamicVideoStyle.videoTouchable}
                        videoStyle={dynamicVideoStyle.videoTouchable}
                        shouldPlay={!isPaused} // Video should play when isPaused is false
                        resizeMode={ResizeMode.COVER}
                        isLooping
                        onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
                      />
                      {!hasBeenPlayed && (
                        // Render the play button only if the video hasn't been played yet
                        <FontAwesomeIcon icon={faPlayCircle} size={128} color='white' style={styles.playPauseIcon}/>
                      )}

                      {hasBeenPlayed && isPaused && (
                        // Render the play button only if the video hasn't been played yet
                        <FontAwesomeIcon icon={faPauseCircle} size={128} color='white' style={styles.playPauseIcon}/>
                      )}
                    </TouchableOpacity>
                  </View>

                  <View style={styles.timestamp}>
                    {videoSource && videoDuration > 0 && (
                      <SelectClipTimestamp
                        videoDuration={videoDuration}
                        currentPlaybackTime={currentPlaybackTime}
                        onTimestampSelected={async (timestamp) => {
                          setPausePoint(timestamp);
                          setJustSeeked(true);
                          if (videoRef.current) {
                            await videoRef.current.setPositionAsync(timestamp * 1000); // Convert to milliseconds
                          }
                        }}
                        videoFinished={videoFinished}
                        videoRef={videoRef} // Pass the videoRef here
                      />
                    )}
                  </View>

                  <Text style={{marginBottom: '5%', textAlign: 'center', alignContent: 'center', color: 'white'}}>Pause at: {pausePoint.toFixed(2)} seconds</Text>

                  {renderButton("Proceed To Question", () => handleShowQuestionForm())}

                </>  
              )}

            </>
          )}
        </View>
      </View>
    </View>
  );
};

export default SubmitClip;