import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  Animated,
  Dimensions,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './style';
import { Audio } from 'expo-av'; // Using expo-av for sound
import { useVideoInteraction } from '../../hooks/user/useVideoInteraction';

interface AnswersModalProps {
  isVisible: boolean;
  onClose: () => void;
  question: string;
  mongoId: string | null;
  videoId: string | null;
  answers: string[];
  onAnswerSelect: (answer: string, index: number, isCorrect: boolean) => void;
  correctAnswer: number;
}

const AnswersModal: React.FC<AnswersModalProps> = ({
  isVisible,
  onClose,
  question,
  mongoId,
  videoId,
  answers,
  onAnswerSelect,
  correctAnswer,
}) => {
  // Disable further taps once an answer is chosen.
  const [answerStatuses, setAnswerStatuses] = useState<(null | 'correct' | 'incorrect')[]>(
    Array(answers.length).fill(null)
  );

  // New state to track the modal's height.
  const [modalHeight, setModalHeight] = useState(0);

  const { handleIncreaseScore, handleAnswerVideo } = useVideoInteraction();

  // Create an array of Animated.Values (one per answer) for button animations.
  const animatedValuesRef = useRef<Animated.Value[]>([]);
  useEffect(() => {
    // Reinitialize animated values when the modal is shown or the answers change.
    animatedValuesRef.current = answers.map(() => new Animated.Value(0));
  }, [answers, isVisible]);

  // Create an Animated.Value to control the modal container's vertical position.
  const modalTranslateY = useRef(new Animated.Value(0)).current;

  // When the modal opens, reset the translation and answer statuses.
  useEffect(() => {
    if (isVisible) {
      modalTranslateY.setValue(0);
      setAnswerStatuses(Array(answers.length).fill(null));
    }
  }, [isVisible, answers, modalTranslateY]);

  // Sound files – ensure these files exist in your assets folder.
  const correctSoundFile = require('../../assets/sounds/Correct.mp3');
  const incorrectSoundFile = require('../../assets/sounds/Incorrect.mp3');

  // Helper function to play a sound.
  const playSound = async (soundFile: any) => {
    try {
      const { sound } = await Audio.Sound.createAsync(soundFile);
      await sound.playAsync();
      // Optionally unload the sound after playing:
      // setTimeout(() => sound.unloadAsync(), 1000);
    } catch (error) {
      console.log('Error playing sound', error);
    }
  };

  // Helper to animate an answer button.
  // Animates from 0 (default) to 1 for a correct answer and to -1 for an incorrect answer.
  const animateAnswer = (index: number, toValue: number) => {
    Animated.timing(animatedValuesRef.current[index], {
      toValue,
      duration: 300,
      useNativeDriver: false, // Native driver can’t animate colors.
    }).start();
  };

  const handlePress = async (answer: string, index: number) => {
    const updatedStatuses = [...answerStatuses];
    const isCorrect = index === correctAnswer;

    // Report the answer via callback.
    onAnswerSelect(answer, index, isCorrect);

    if (isCorrect) {
      handleIncreaseScore(mongoId || '');
      updatedStatuses[index] = 'correct';
      animateAnswer(index, 1); // Animate the pressed answer to "correct" (green)
      playSound(correctSoundFile);
    } else {
      updatedStatuses[index] = 'incorrect';
      // Also animate the correct answer button for clarity.
      updatedStatuses[correctAnswer] = 'correct';
      animateAnswer(index, -1); // Animate the pressed answer to "incorrect" (red)
      animateAnswer(correctAnswer, 1); // Animate the correct answer to "correct" (green)
      playSound(incorrectSoundFile);
    }
    setAnswerStatuses(updatedStatuses);

    // Record that the user answered the video.
    await handleAnswerVideo(videoId || '', index, isCorrect);

    // Slide the modal container down while ensuring the bottom remains in view.
    const windowHeight = Dimensions.get('window').height;
    let targetTranslateY = windowHeight / 3; // Default translation value.

    // If the modal's height has been measured and it fits within the window,
    // ensure that translating won't push its bottom off-screen.
    if (modalHeight > 0 && modalHeight < windowHeight) {
      // Since the modal is centered, its initial bottom is:
      //   (windowHeight + modalHeight) / 2
      // We want the new bottom (initialBottom + translateY) to be <= windowHeight.
      // Thus, translateY must be <= (windowHeight - modalHeight) / 2.
      const maxAllowedTranslate = (windowHeight - modalHeight) / 2;
      targetTranslateY = Math.min(windowHeight / 3, maxAllowedTranslate);
    }

    Animated.timing(modalTranslateY, {
      toValue: targetTranslateY,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  // Wrap TouchableOpacity with Animated.
  const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableOpacity
        style={styles.centeredView}
        activeOpacity={1}
        onPressOut={onClose}
      >
        {/* Animate the modal container */}
        <Animated.View
          // Measure the modal's height so we can compute a safe translation.
          onLayout={(e) => {
            const { height: layoutHeight } = e.nativeEvent.layout;
            setModalHeight(layoutHeight);
          }}
          style={[
            styles.modalView,
            { transform: [{ translateY: modalTranslateY }] },
          ]}
          onStartShouldSetResponder={() => true}
        >
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <FontAwesomeIcon icon={faTimes} color="white" size={24} />
          </TouchableOpacity>

          <Text style={styles.modalText}>{question}</Text>
          {answers.map((answer, index) => {
            // Interpolate the animated value into background color and scale.
            const animatedStyle = {
              backgroundColor: animatedValuesRef.current[index]?.interpolate({
                inputRange: [-1, 0, 1],
                outputRange: ['#ff4d4d', '#000', '#4CAF50'], // red for incorrect, black for default, green for correct
              }),
              transform: [
                {
                  scale: animatedValuesRef.current[index]?.interpolate({
                    inputRange: [-1, 0, 1],
                    outputRange: [0.95, 1, 1.05], // slight shrink for incorrect, slight pop for correct
                  }),
                },
              ],
            };

            return (
              <AnimatedTouchableOpacity
                key={index}
                style={[styles.answerButton, animatedStyle]}
                onPress={() => handlePress(answer, index)}
                disabled={answerStatuses.some(status => status !== null)} // disable after one tap
              >
                <Text style={styles.answerText}>{answer}</Text>
                {answerStatuses[index] && (
                  <FontAwesomeIcon
                    icon={answerStatuses[index] === 'correct' ? faCheck : faTimes}
                    color={answerStatuses[index] === 'correct' ? 'green' : 'red'}
                    size={20}
                  />
                )}
              </AnimatedTouchableOpacity>
            );
          })}
        </Animated.View>
      </TouchableOpacity>
    </Modal>
  );
};

export default AnswersModal;
